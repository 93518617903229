.container {
  width: 100%;
  height: 100%;
}

.card {
  border: none;
  background-color: #fff;
}

.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 1px 10px 20px rgba(30, 30, 30, 0.8);
  top: -4px;
}

.card-img-top {
  border: none;
  height: 30vh;
  object-fit: cover;
}

.card-text {
  padding: 0.5rem 2rem;
}

.btn {
  background-color: transparent;
  color: black;
  border-color: black;
  margin: 0.5rem;
}

.btn:hover {
  background-color: #6b5e75;
  border-color: transparent;
}

/* @media (max-width: 768px) {
  .card {
    padding: 1rem 3rem;
  }
}

@media (min-width: 768px) {
  .card {
    padding: 0rem;
  }
} */
