.navbar {
  background-color: #6b5e75;
}

.nav-link,
.navbar-brand {
  color: #fff;
}

.form {
  display: none;
}
